<template>
  <BasePage :overlayValue="cartGetter.loadingCart">
    <div style="width: 100%;height: 130px;" class="d-flex align-center">
      <Container>
        <h2 class="display-1 primary--text">{{ $t('shoppingCart') }}</h2>
      </Container>
    </div>
    <Container>
      <!-- <v-overlay :value="cartGetter.loadingCart">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay> -->
      <template>
        <div class="body-1 my-2 primary--text" style="color: #333">
          {{ (cartGetter.cart && cartGetter.cart.length) || '0' }}
          {{ $t('courseInCart') }}
        </div>
        <div v-if="cartGetter.cart && cartGetter.cart.length">
          <v-row>
            <v-col cols="12" sm="7" md="9" order="2" order-sm="1">
              <v-sheet class="pa-5">
                <v-row
                  v-for="(cartItem, idx) in cartGetter.cart"
                  :key="cartItem.id"
                >
                  <v-col cols="11">
                    <div class="d-flex">
                      <div
                        style="width: 130px;height: 80px;"
                        class="d-flex align-center justify-center"
                      >
                        <!-- src="https://picsum.photos/id/11/500/300" -->
                        <v-img
                          :src="cartItem.course_id.featured_image.data.full_url"
                          style="objectFit:contain;width:100%;height:100%"
                        />
                      </div>
                      <div class="mx-2">
                        <div class="body-2">
                          {{ cartItem.course_id[lang + 'title'] }}
                        </div>
                        <div class="caption">
                          <span class="font-weight-bold">{{ $t('by') }}</span>
                          {{
                            ' ' +
                              cartItem.course_id.instructor_id.first_name +
                              ' ' +
                              cartItem.course_id.instructor_id.last_name
                          }}
                        </div>
                      </div>
                    </div>
                    <v-divider
                      v-if="idx + 1 < cartGetter.cart.length"
                      class="mt-6"
                    />
                  </v-col>
                  <v-col cols="1">
                    <div class="d-flex justify-center">
                      <v-btn
                        icon
                        @click="
                          removeCartItemAction({
                            productId: cartItem.id,
                            type: 'cart',
                          })
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="5" md="3" order="1" order-sm="2">
              <v-sheet class="pa-5">
                <div class="body-1">{{ $t('total') }}:</div>
                <div style="fontSize:28px; fontWeight: bold">
                  <span>USD</span>
                  {{ calcPrice('after_discount') }}
                </div>
                <div class="before-discount">
                  <span>USD</span>
                  {{ calcPrice('before_discount') }}
                </div>
                <div class="before-discount">{{ discountOff() }}% off</div>
                <v-btn
                  elevation="0"
                  style="width: 100%;height:50px"
                  color="secondary"
                  outlined
                  class="mt-6"
                >
                  {{ $t('checkout') }}</v-btn
                >

                <!-- <div class="d-flex align-center justify-center mt-8" dir="ltr">
                  <v-text-field
                    outlined
                    dense
                    :label="$t('enterCoupon')"
                    class="rounded-r-0"
                    style="width: 50%;height: 40px"
                  />
                  <v-btn
                    class="rounded-l-0"
                    color="primary"
                    style="width: 20%;height: 40px"
                    elevation="0"
                    link
                    >{{ $t('send') }}</v-btn
                  >
                </div> -->
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <v-sheet
          v-else
          class="d-flex flex-column align-center justify-center py-8 white"
          style="border: 1px solid #eee !important;"
        >
          <v-icon class="grey--text" x-large style="fontSize:100px"
            >mdi-cart</v-icon
          >
          <span class="body-2 my-2">
            {{ $t('emptyCart') }}
          </span>
          <v-btn
            elevation="0"
            color="primary"
            @click="$router.push(`/courses?type=1`)"
          >
            {{ $t('keepShopping') }}</v-btn
          >
        </v-sheet>
      </template>
    </Container>
  </BasePage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    ...mapActions(['removeCartItemAction']),
    calcPrice(period) {
      return this.cartGetter.cart.reduce(
        (a, c) => +(a + c.course_id[period]),
        0
      );
    },
    discountOff() {
      // let before = this.cartGetter.cart.reduce(
      //     (a, c) => a + c.course_id.before_discount,
      //     0
      //   ),
      //   after = this.cartGetter.cart.reduce(
      //     (a, c) => a + c.course_id.after_discount,
      //     0
      //   );
      return Math.floor(
        ((this.calcPrice('before_discount') -
          this.calcPrice('after_discount')) /
          this.calcPrice('before_discount')) *
          100
      );
    },
  },
  computed: {
    ...mapGetters(['cartGetter']),
  },
};
</script>

<style>
.before-discount {
  text-decoration: line-through;
  color: var(--v-error-base);
}
</style>
